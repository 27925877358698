import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import { SortingState } from '@tanstack/react-table';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Table } from '../../../../components/table';
import { THEME } from '../../../../constants';
import CONSTANT_TRANSACTION from '../../../../components/transfers-content/constant';
import 'react-tabs/style/react-tabs.css';
import 'react-tooltip/dist/react-tooltip.css';
import { StyledFieldset, StyledLegend } from '../../common-components';

Modal.defaultStyles.overlay.backgroundColor = 'rgb(144 144 144 / 98%)';

const WrapContent = styled.div`
  margin-top: 30px;
  @media (max-width: ${THEME.breakpoint}px) {
    padding-bottom: 0;
  }
`;

const TitleTable = styled.h2`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
  color: ${THEME.white};
  margin-top: 20px;
  position: relative;
  display: table;
  padding-bottom: 15px;
  margin-bottom: 20px;
  cursor: pointer;
  &:after {
    content: '';
    border: 1px solid ${THEME.yellow};
    width: 100%;
    bottom: 0;
    position: absolute;
    left: 0;
  }
  &:hover {
    opacity: 0.9;
  }
`;

const TableFilterContainer = styled.div.attrs({
  className: 'TableFilterContainer',
})`
  display: grid;
  grid-template-columns: repeat(5, auto) 1fr;
  gap 1em;
`;

const ViewButton = styled.button`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 10px;
  display: flex;
  padding: 3px;
  align-items: center;
  text-align: center;
  background: transparent;
  color: #fbb919;
  cursor: pointer;
  text-transform: uppercase;
  &:hover {
    opacity: 0.9;
  }
`;

const WrapTrasactionDesktop = styled.div`
  @media (max-width: ${THEME.breakpoint}px) {
    display: none;
  }
`;

const WrapInput = styled.div.attrs({
  className: 'WrapInput',
})``;

export const Transfers = ({ transfers, loadingTransfersResponse }) => {
  // table columns:
  const columns = [
    {
      accessorFn: (row: any) => row.number,
      id: 'number',
      cell: (info: any) => info.getValue(),
      header: () => <span>Number</span>,
      footer: (props: any) => props.column.id,
    },
    {
      accessorFn: (row: any) => <a href={row?.bitach_link || "#"}>{row?.bitach_id || ""}</a>,
      id: 'bitach id',
      cell: (info: any) => info.getValue(),
      header: () => <span>Bitach ID</span>,
      footer: (props: any) => props.column.id,
    },
    {
      accessorFn: (row: any) => row.date,
      id: 'date',
      cell: (info: any) => info.getValue(),
      header: () => <span>Date</span>,
      footer: (props: any) => props.column.id,
    },
    {
      accessorKey: 'from',
      header: () => <span>From</span>,
      cell: (info: any) => info.getValue(),
      footer: (props: any) => props.column.id,
    },
    {
      accessorKey: 'to',
      header: () => <span>To</span>,
      cell: (info: any) => info.getValue(),
      footer: (props: any) => props.column.id,
    },
    {
      accessorKey: 'fee',
      header: () => <span>Fee</span>,
      cell: (info: any) => info.getValue(),
      footer: (props: any) => props.column.id,
    },
    {
      accessorKey: 'rate',
      header: () => <span>Rate</span>,
      cell: (info: any) => info.getValue(),
      footer: (props: any) => props.column.id,
    },
    {
      accessorFn: (row: any) => row.rateDate,
      id: 'rateDate',
      cell: (info: any) => info.getValue(),
      header: () => <span>Rate Date</span>,
      footer: (props: any) => props.column.id,
    },
    {
      accessorKey: 'debited',
      header: () => <span>Debited</span>,
      cell: (info: any) => info.getValue(),
      footer: (props: any) => props.column.id,
    },
    {
      accessorKey: 'credited',
      header: () => <span>Credited</span>,
      cell: (info: any) => info.getValue(),
      footer: (props: any) => props.column.id,
    },
    {
      accessorKey: 'status',
      header: () => <span>Status</span>,
      cell: (info: any) => {
        return (
          <>
            <div
              id={`status-tooltip-${info.getValue()?.toLowerCase()}-${info.row.id}`}
              className={`tag tag-${info.getValue()?.toLowerCase()}`}
            >
              {info.getValue()}
            </div>
            <ReactTooltip
              anchorId={`status-tooltip-${info.getValue()?.toLowerCase()}-${info.row.id}`}
              place="bottom"
              className="adp-tooltip--component"
              content={CONSTANT_TRANSACTION[info.getValue()?.toLowerCase()]}
            />
          </>
        );
      },
      footer: (props: any) => props.column.id,
    },
    {
      accessorFn: (row: any) => row.completedAt,
      id: 'completedAt',
      cell: (info: any) => info.getValue(),
      header: () => <span>Completed</span>,
      footer: (props: any) => props.column.id,
    },
    // {
    //   accessorKey: 'action',
    //   header: () => <span>Details</span>,
    //   cell: (info: any) => {
    //     return (
    //       <ViewButton type="button" onClick={() => console.log(info)}>
    //         Details
    //       </ViewButton>
    //     );
    //   },
    //   footer: (props: any) => props.column.id,
    // },
  ];

  const [currentPage, setPage] = useState<any>(1);
  const [sorting, setSorting] = React.useState<SortingState>([]);

  if (!transfers?.length) return;

  // console.table(transfers);

  return (
    <StyledFieldset>
      <StyledLegend>Transfers</StyledLegend>
      <WrapContent>
        <WrapTrasactionDesktop>
          <div>
            <Table
              data={transfers}
              total={transfers.length}
              columns={columns}
              setSorting={setSorting}
              sorting={sorting}
              setPage={setPage}
              page={currentPage}
              pageSize={20}
              loading={loadingTransfersResponse}
            />
          </div>
        </WrapTrasactionDesktop>
      </WrapContent>
    </StyledFieldset>
  );
};
