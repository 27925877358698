import Modal from 'react-modal';
import { CUSTOM_MODAL_STYLE } from '../../constants';
import { THEME } from '../../constants';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import styled from 'styled-components';
import moment from 'moment';

const WrapTransferDetail = styled.div`
  justify-content: space-between;
  display: flex;
  text-align: right;
`;

const WrapButton = styled.button`
  cursor: pointer;
  -webkit-appearance: button;
  background-color: rgba(255, 255, 255, 0.12);
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 1px 0 0 rgba(255, 255, 255, 0.1);
  color: ${THEME.white};
  font-size: 20px;
  height: 56px;
  width: -webkit-fill-available;
  margin: 20px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WrapDocumentFrame = styled.iframe`
  height: 100%;
  width: 100%;
  border: none;
`;

const ModalContent = ({ closeDocumentPreviewModal, documentInstance }) => {
  const cls = documentInstance.type.value.replaceAll(' ', '_').toLowerCase();
  return (
    <>
      <h2
        className="modal-title"
        style={{ flexWrap: 'wrap', paddingRight: '25px', marginBottom: '15px' }}
      >
        {/* <span className="Navbar__button Navbar__button--is-left"></span> */}
        <div className="d-flex align-items-center">
          <div
            id={`status-tooltip-${cls}-${documentInstance.type.value} pe-2`}
            className={`tag tag-${cls}`}
          >
            {documentInstance.type.value}
          </div>
          <span style={{ whiteSpace: 'nowrap', paddingLeft: '15px' }}>
            {documentInstance.file_metadata.original_name}
          </span>
        </div>
        <span
          style={{ position: 'absolute', right: 0 }}
          className="Navbar__button Navbar__button--is-right"
          onClick={closeDocumentPreviewModal}
        >
          <i className="Icon Navbar__icon">
            <svg viewBox="0 0 24 24">
              <path
                fill="#fff"
                d="M10.695 12.5l-4.342 4.343c-.482.481-.468 1.276.03 1.774.498.499 1.293.512 1.774.03l4.343-4.342 4.317 4.317c.482.482 1.276.468 1.775-.03.498-.499.512-1.293.03-1.775L14.305 12.5l4.317-4.317c.482-.482.468-1.276-.03-1.775-.498-.498-1.293-.512-1.775-.03L12.5 10.695 8.157 6.352c-.481-.481-1.276-.468-1.774.03-.499.5-.512 1.294-.03 1.775l4.342 4.343z"
              ></path>
            </svg>
          </i>
        </span>
      </h2>
      <div className="modal-body body-inheri" style={{ height: '78%', padding: 0 }}>
        <WrapDocumentFrame src={documentInstance?.file || documentInstance?.preview_url} />
      </div>
      <WrapButton onClick={closeDocumentPreviewModal}>Close</WrapButton>
    </>
  );
};

export const DocumentPreviewModal = ({
  documentPreviewModalIsOpen,
  closeDocumentPreviewModal,
  documentInstance,
}) => {
  return (
    <Modal
      className="no-transform"
      style={{
        content: {
          position: 'absolute',
          inset: 40,
          overflow: 'auto',
          outline: 'none',
          padding: 20,
          boxShadow: '0 8px 16px 0 rgb(0 0 0 / 20%)',
          border: '1px solid #353F56',
          background: '#353F56',
          borderRadius: '8px',
          color: '#fff',
          // padding: 0,
        },
      }}
      isOpen={documentPreviewModalIsOpen}
      onRequestClose={closeDocumentPreviewModal}
      appElement={document.getElementById('root')}
    >
      <ModalContent
        closeDocumentPreviewModal={closeDocumentPreviewModal}
        documentInstance={documentInstance}
      />
    </Modal>
  );
};
