import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import useAxios from 'axios-hooks';
import styled from 'styled-components';
import moment from 'moment';

import { THEME } from '../../../constants';
import { LoadingInPlaceTransparent } from '../../../components/loading';
import { PersonalDetails } from './components/personal-details';
import { UserDetails } from './components/user-details';
import { AddressDetails } from './components/address-details';
import { PhoneDetails } from './components/phone-details';
import { AliasDetails } from './components/alias-details';
import { IdentificationDetails } from './components/identification-details';
import { DocumentsDetails } from './components/documents';
import { BankAccountsDetails } from './components/bank-accounts';
import { Transfers } from './components/transfers';
import { StyledButton } from '../common-components';
import { ReferralDetails } from './components/referral-details';

const ProfileContainer = styled.div.attrs({
  className: 'ProfileContainer',
})`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
  grid-template-rows: auto 1fr;
`;

const SearchContainer = styled.div.attrs({
  className: 'SearchContainer',
})`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 1em;
  margin: 1em 0;
`;

const ControllerContainer = styled.div.attrs({
  className: 'ControllerContainer',
})`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1em;
  margin: 1em 0;
`;

const WrapIdentifications = styled.div.attrs({
  className: 'WrapIdentifications',
})`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
  color: ${THEME.white};
`;

const StyledInput = styled.input.attrs({
  className: 'StyledInput',
})`
  background-color: ${THEME.dark_blue};
  color: ${THEME.white};
  border: none;
  border-radius: 10px;
  padding: 0 10px;
  height: 100%;
  border: none;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: rgb(0 10 20 / 40%) 0px 8px 24px;
  &:focus {
    border: none;
    outline: none;
  }
`;

const PhoneAndAliasContainer = styled.div.attrs({
  className: 'PhoneAndAliasContainer',
})`
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: 1em;
`;

const TwoColumnContainer = styled.div.attrs({
  className: 'TwoColumnContainer',
})`
  grid-column: span 2;
`;

export const AdminAccountsContent = ({ setToken }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [loginasButtonIsEnabled, setLoginasButtonIsEnabled] = useState(true);
  const [searchButtonIsEnabled, setSearchButtonIsEnabled] = useState(false);
  const [approveUserButtonIsEnabled, setApproveUserButtonIsEnabled] = useState(true);
  const [registerUserButtonIsEnabled, setRegisterUserButtonIsEnabled] = useState(true);
  const [email, setEmail] = useState(null);
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [address, setAddress] = useState(null);
  const [socialSecurityNumber, setSocialSecurityNumber] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [alias, setAlias] = useState(null);
  const [primaryIdentification, setPrimaryIdentification] = useState(null);
  const [secondaryIdentification, setSecondaryIdentification] = useState(null);
  const [documents, setDocuments] = useState(null);
  const [USBankAccounts, setUSBankAccounts] = useState(null);
  const [ILBankAccounts, setILBankAccounts] = useState(null);
  const [bankAccountBillingAddresses, setBankAccountBillingAddresses] = useState(null);
  const [bankAccountDocuments, setBankAccountDocuments] = useState(null);
  const [bankAccountHolders, setBankAccountHolders] = useState(null);
  const [transfers, setTransfers] = useState(null);

  const [{ data: usersResponse, loading: usersResponseIsLoading }, getUsers] = useAxios('/users/', {
    manual: true,
  });
  const [
    { data: bitachRecipientResponse, loading: bitachRecipientResponseIsLoading },
    getBitachRecipients,
  ] = useAxios(`/bitach_recipients/?user__uuid=${user?.uuid}`, {
    manual: true,
  });
  const [
    { data: unityfiLocationUserResponse, loading: setUnityfiLocationUserResponse },
    getUnityfiLocationUser,
  ] = useAxios(`/unityfi_location_users/?user__uuid=${user?.uuid}`, { manual: true });
  const [{ data: addressesResponse, loading: addressesResponseIsLoading }, getAddresses] = useAxios(
    `/addresses/?user__uuid=${user?.uuid}`,
    { manual: true },
  );
  const [{ data: phoneNumbersResponse, loading: phoneNumbersResponseIsLoading }, getPhoneNumbers] =
    useAxios(`/phone_numbers/?user__uuid=${user?.uuid}`, { manual: true });
  const [{ data: profilesResponse, loading: profilesResponseIsLoading }, getProfiles] = useAxios(
    `/profiles/?user__uuid=${user?.uuid}`,
    { manual: true },
  );
  const [
    { data: socialSecurityNumberResponse, loading: socialSecurityNumberResponseisLoading },
    getSocialSecurityNumber,
  ] = useAxios(`/social_security_numbers/?user__uuid=${user?.uuid}`, { manual: true });
  const [
    { data: identificationsResponse, loading: identificationsResponseIsLoading },
    getIdentifications,
  ] = useAxios(`/identifications/?user__uuid=${user?.uuid}`, { manual: true });
  const [{ data: aliasesResonse, loading: aliasesResponseIsLoading }, getAliases] = useAxios(
    `/aliases/?user__uuid=${user?.uuid}`,
    { manual: true },
  );
  const [{ data: documentsResponse, loading: documentResponseIsLoading }, getDocuments] = useAxios(
    `/documents/?user__uuid=${user?.uuid}`,
    { manual: true },
  );
  const [{ data: bankAccountsResponse, loading: bankAccountsResponseIsLoading }, getBankAccounts] =
    useAxios(`/bank_accounts/?user__uuid=${user?.uuid}`, { manual: true });
  const [
    { data: bankAccountHoldersResponse, loading: bankAccountHoldersResponseIsLoading },
    getBankAccountHolders,
  ] = useAxios(`/bank_account_holders/?user__uuid=${user?.uuid}`, { manual: true });
  const [
    {
      data: bankAccountBillingAddressesResponse,
      loading: bankAccountBillingAddressesResponseIsLoading,
    },
    getBankAccountBillingAddresses,
  ] = useAxios(`/bank_account_billing_addresses/?user__uuid=${user?.uuid}`, { manual: true });
  const [
    { data: bankAccountDocumentsResponse, loading: bankAccountDocumentsResponseIsLoading },
    getBankAccountDocuments,
  ] = useAxios(`/bank_account_documents/?user__uuid=${user?.uuid}`, { manual: true });
  const [{ data: transfersResponse, loading: transfersResponseIsLoading }, getTransfers] = useAxios(
    `/transfers/?user__uuid=${user?.uuid}`,
    { manual: true },
  );
  const [{ data: USBanksResponse, loading: USBanksResponseIsLoading }, getUSBanks] = useAxios(
    '/banks/?country__alpha_2=US',
    { manual: true },
  );
  const [{ data: ILBanksResponse, loading: ILBanksResponseIsLoading }, getILBanks] = useAxios(
    '/banks/?country__alpha_2=IL',
    { manual: true },
  );
  const [{ data: referralsResponse, loading: referralsResponseIsLoading }, getReferrals] = useAxios(
    `/referrals/?user__uuid=${user?.uuid}`,
    { manual: true },
  );
  const [, loginas] = useAxios(`/loginas/?email=${email}`, { manual: true });
  const [, updateProfile] = useAxios(
    {
      url: `/profiles/${profile?.uuid}/`,
      method: 'PATCH',
    },
    { manual: true },
  );

  const selectedEmail = searchParams.get('user');

  const USBanks = USBanksResponse?.results;
  const ILBanks = ILBanksResponse?.results;

  const bitachRecipient = bitachRecipientResponse?.results[0];
  const unityfiLocationUser = unityfiLocationUserResponse?.results[0];
  const referrals = referralsResponse?.results;

  const userIsApprovable =
    primaryIdentification?.is_verified &&
    secondaryIdentification?.is_verified &&
    socialSecurityNumber?.verification_document_is_signed;

  // console.table(transfers);

  const renderableTransfers = transfers?.map((transfer) => ({
    date: moment(transfer?.created_at).format('MMM DD, YYYY'),
    completedAt: transfer?.completed_at ? moment(transfer?.completed_at).format('MMM DD, YYYY') : '',
    fee: transfer?.fee?.percent,
    rate: transfer?.rate?.amount,
    rateDate: moment(transfer?.rate?.date).format('MMM DD, YYYY'),
    credited: transfer?.type?.currency_out?.symbol + (transfer?.amount_receiving || 'TBD'),
    debited: transfer?.type?.currency_in?.symbol + (transfer?.amount_sending || 'TBD'),
    status: transfer?.status,
    number: transfer?.number,
    user: transfer?.user,
    from: transfer?.bank_account_sending?.name,
    to: transfer?.bank_account_receiving?.name,
    origin: transfer,
    bitach_id: transfer?.bitach_transfer,
    bitach_link: transfer?.bitach_link,
  }));

  useEffect(() => {
    if (!selectedEmail) return;
    setEmail(selectedEmail);
    setSearchButtonIsEnabled(false);
    getUsers(`/users/?email=${selectedEmail}`)
      .then((response) => {
        setSearchButtonIsEnabled(true);
        if (response.data.count === 0) return alert('user not found');
        const user = response.data.results[0];
        setUser(user);
      })
      .catch((error) => console.log(error));
  }, [selectedEmail]);

  useEffect(() => {
    if (!user) return;
    getAddresses();
    getSocialSecurityNumber();
    getPhoneNumbers();
    getProfiles();
    getIdentifications();
    getAliases();
    getDocuments();
    getBankAccounts();
    getBankAccountBillingAddresses();
    getBankAccountDocuments();
    getBankAccountHolders();
    getTransfers();
    getUSBanks();
    getILBanks();
    getBitachRecipients();
    getUnityfiLocationUser();
    getReferrals();
  }, [user]);

  useEffect(() => {
    setProfile(profilesResponse?.results[0]);
  }, [profilesResponse]);

  useEffect(() => {
    setAddress(addressesResponse?.results[0]);
  }, [addressesResponse]);

  useEffect(() => {
    setPhoneNumber(phoneNumbersResponse?.results[0]);
  }, [phoneNumbersResponse]);

  useEffect(() => {
    setSocialSecurityNumber(socialSecurityNumberResponse?.results[0]);
  }, [socialSecurityNumberResponse]);

  useEffect(() => {
    setAlias(aliasesResonse?.results[0]);
  }, [aliasesResonse]);

  useEffect(() => {
    setDocuments(documentsResponse?.results);
  }, [documentsResponse]);

  useEffect(() => {
    setUSBankAccounts(
      bankAccountsResponse?.results?.filter(
        (bankAccount) => bankAccount?.country?.alpha_2 === 'US',
      ),
    );
    setILBankAccounts(
      bankAccountsResponse?.results?.filter(
        (bankAccount) => bankAccount?.country?.alpha_2 === 'IL',
      ),
    );
  }, [bankAccountsResponse]);

  useEffect(() => {
    setBankAccountDocuments(bankAccountDocumentsResponse?.results);
  }, [bankAccountDocumentsResponse]);

  useEffect(() => {
    setBankAccountBillingAddresses(bankAccountBillingAddressesResponse?.results);
  }, [bankAccountBillingAddressesResponse]);

  useEffect(() => {
    setBankAccountHolders(bankAccountHoldersResponse?.results);
  }, [bankAccountHoldersResponse]);

  useEffect(() => {
    setTransfers(transfersResponse?.results);
  }, [transfersResponse]);

  useEffect(() => {
    if (identificationsResponse?.results.length >= 2) {
      setPrimaryIdentification(identificationsResponse?.results[0]);
      setSecondaryIdentification(identificationsResponse?.results[1]);
    } else if (identificationsResponse?.results.length >= 1) {
      setPrimaryIdentification(identificationsResponse?.results[0]);
      setSecondaryIdentification({});
    } else {
      setPrimaryIdentification({});
      setSecondaryIdentification({});
    }
  }, [identificationsResponse]);

  useEffect(() => {
    setSearchButtonIsEnabled(Boolean(email));
  }, [email]);

  const handleEmailOnChange = (event) => setEmail(event.target.value);

  const handleEmailOnSubmit = () => {
    setSearchButtonIsEnabled(false);
    getUsers(`/users/?email=${email}`)
      .then((response) => {
        setSearchButtonIsEnabled(true);
        if (response.data.count === 0) return alert('user not found');
        const user = response.data.results[0];
        setUser(user);
      })
      .catch((error) => console.log(error));
  };

  const login = async (data) => {
    await setToken(data);
    if (data?.user?.is_registered) return (window.location.href = '/account');
    else return (window.location.href = '/join');
  };

  const handleLoginas = () => {
    setLoginasButtonIsEnabled(false);
    loginas().then((response) => {
      setLoginasButtonIsEnabled(true);
      login(response.data);
    });
  };

  const handleRegisterButtonOnClick = () => {
    setRegisterUserButtonIsEnabled(false);
    updateProfile({
      data: {
        is_registered: !profile?.is_registered,
      },
    }).then((response) => {
      setRegisterUserButtonIsEnabled(true);
      setProfile((profile) => ({
        ...profile,
        is_registered: !profile?.is_registered,
      }));
    });
  };

  const handleApproveButtonOnClick = () => {
    setApproveUserButtonIsEnabled(false);
    updateProfile({
      data: {
        is_approved: !profile?.is_approved,
      },
    })
      .then((response) => {
        setApproveUserButtonIsEnabled(true);
        setProfile((profile) => ({
          ...profile,
          is_approved: !profile?.is_approved,
        }));
      })
      .catch((error) => {
        setApproveUserButtonIsEnabled(true);
        const data = error?.response?.data;
        if (!data) return console.log(error);
        let errorMessage = '';
        for (const key in data) {
          data[key].forEach((message) => {
            errorMessage += `[${key}]: ${message}\n`;
          });
        }
        alert(errorMessage);
      });
  };

  const dataIsLoading =
    addressesResponseIsLoading ||
    bankAccountDocumentsResponseIsLoading ||
    bankAccountsResponseIsLoading ||
    documentResponseIsLoading ||
    phoneNumbersResponseIsLoading ||
    profilesResponseIsLoading ||
    identificationsResponseIsLoading ||
    transfersResponseIsLoading;

  return (
    <ProfileContainer>
      <SearchContainer>
        <StyledInput value={email || ''} onChange={handleEmailOnChange} />
        <StyledButton
          disabled={!searchButtonIsEnabled || dataIsLoading}
          onClick={handleEmailOnSubmit}
        >
          search
        </StyledButton>
      </SearchContainer>
      {dataIsLoading ? (
        <>
          <span></span>
          <TwoColumnContainer style={{ height: '70vh', backgroundColor: 'transparent' }}>
            <LoadingInPlaceTransparent />
          </TwoColumnContainer>
        </>
      ) : user ? (
        <>
          <ControllerContainer>
            <StyledButton transparent onClick={handleLoginas} disabled={!loginasButtonIsEnabled}>
              loginas
            </StyledButton>
            <StyledButton
              disabled={!registerUserButtonIsEnabled}
              onClick={handleRegisterButtonOnClick}
              transparent={!profile?.is_registered}
            >
              {profile?.is_registered ? 'Registered' : 'Register User'}
            </StyledButton>
            <StyledButton
              disabled={!approveUserButtonIsEnabled || !userIsApprovable}
              onClick={handleApproveButtonOnClick}
              transparent={!profile?.is_approved}
            >
              {profile?.is_approved ? 'Approved' : 'Approve User'}
            </StyledButton>
          </ControllerContainer>
          <UserDetails
            user={user}
            setUser={setUser}
            profile={profile}
            setProfile={setProfile}
            bitachRecipient={bitachRecipient}
            unityfiLocationUser={unityfiLocationUser}
          />
          <PersonalDetails
            profile={profile}
            setProfile={setProfile}
            socialSecurityNumber={socialSecurityNumber}
          />
          <AddressDetails address={address} setAddress={setAddress} />
          <PhoneAndAliasContainer>
            <PhoneDetails phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} />
            {user?.entity === 'ISRAELI_CITIZEN' && (
              <AliasDetails alias={alias} setAlias={setAlias} />
            )}
          </PhoneAndAliasContainer>
          <WrapIdentifications style={{ gridColumn: 'span 2' }}>
            <IdentificationDetails
              designation="primary"
              identification={primaryIdentification}
              setIdentification={setPrimaryIdentification}
            />
            <IdentificationDetails
              designation="secondary"
              identification={secondaryIdentification}
              setIdentification={setSecondaryIdentification}
            />
          </WrapIdentifications>
          <TwoColumnContainer>
            <DocumentsDetails user={user} documents={documents} getDocuments={getDocuments} />
          </TwoColumnContainer>
          <BankAccountsDetails
            user={user}
            getBankAccountDocuments={getBankAccountDocuments}
            country="US"
            banks={USBanks}
            bankAccounts={USBankAccounts}
            setBankAccounts={setUSBankAccounts}
            bankAccountDocuments={bankAccountDocuments}
            bankAccountHolders={bankAccountHolders}
            bankAccountBillingAddresses={bankAccountBillingAddresses}
            setBankAccountBillingAddresses={setBankAccountBillingAddresses}
          />
          <BankAccountsDetails
            user={user}
            getBankAccountDocuments={getBankAccountDocuments}
            country="IL"
            banks={ILBanks}
            bankAccounts={ILBankAccounts}
            setBankAccounts={setILBankAccounts}
            bankAccountDocuments={bankAccountDocuments}
            bankAccountHolders={bankAccountHolders}
            bankAccountBillingAddresses={bankAccountBillingAddresses}
            setBankAccountBillingAddresses={setBankAccountBillingAddresses}
          />
          <TwoColumnContainer>
            <Transfers
              transfers={renderableTransfers}
              loadingTransfersResponse={transfersResponseIsLoading}
            />
          </TwoColumnContainer>
          <ReferralDetails referrals={referrals} />
        </>
      ) : null}
    </ProfileContainer>
  );
};
