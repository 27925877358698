import React, { useEffect, useState } from 'react'
import { debounce } from '@mui/material'
import useAxios from 'axios-hooks'
import styled from 'styled-components'
import moment from 'moment'

import { EditableInput, SavingContainer, StyledButton, StyledFieldset, StyledLegend } from '../common-components'
import { Loading, LoadingInPlace } from '../../../components/loading'
import { useIsMount } from '../../../hooks/useIsMount'
import { DataTableContainer, StyledDataTable, StyledLink } from '../accounts/components/datatables'
import CancelButton from './status-buttons/cancel'
import PreapprovedButton from './status-buttons/preapproved'
import PendingButton from './status-buttons/pending'
import FailedButton from './status-buttons/failed'
import ProcessingButton from './status-buttons/processing'
import EnrouteButton from './status-buttons/enroute'

const TransfersContainer = styled.div.attrs({
  className: 'TransfersContainer',
})`
  display: flex;
  gap: 1em;
  height: 100%;
  flex-direction: column;
`

const datetimeSort = (rowA, rowB, field) => {
  if (!rowA[field]) return -1
  else if (!rowB[field]) return 1
  else if (rowA[field] > rowB[field]) return 1
  else if (rowA[field] < rowB[field]) return -1
  else return 0
}

const PercentFeeCell = ({ setSavingIsActive, updatePendingTransferFee, row }) => {
  const isFirstRender = useIsMount()
  const [percentFee, setPercentFee] = useState(row?.fee?.percent)
  const [{ loading: transferIsSaving }, updateTransfer] = useAxios(
    {
      url: `/transfer_fees/${row?.fee?.uuid}/`,
      method: 'PATCH',
      data: {
        percent: percentFee,
      },
    },
    { manual: true },
  )

  useEffect(() => {
    if (isFirstRender) return
    const debouncedUpdate = debounce(() => {
      updateTransfer().catch((error) => {
        if (error.code === 'ERR_CANCELED') return
      })
    }, 500)
    debouncedUpdate()
    return () => {
      debouncedUpdate.clear()
    }
  }, [percentFee])

  useEffect(() => {
    setSavingIsActive(transferIsSaving)
  }, [transferIsSaving])

  const handleInputOnChange = (event) => {
    setPercentFee(event.target.value)
    updatePendingTransferFee(row?.uuid, {
      ...row?.fee,
      percent: event.target.value,
    })
  }

  return <EditableInput type="number" value={percentFee} onChange={handleInputOnChange} />
}

const NotesCell = ({ setSavingIsActive, updatePendingTransferNotes, row, isUpdatingData }) => {
  const isFirstRender = useIsMount()
  const [notes, setNotes] = useState(row?.notes || '')
  const [{ loading: transferIsSaving }, updateTransfer] = useAxios(
    {
      url: `/transfers/${row?.uuid}/`,
      method: 'PATCH',
      data: { notes },
    },
    { manual: true },
  )

  useEffect(() => {
    if (isFirstRender) return
    const debouncedUpdate = debounce(() => {
      updateTransfer().catch((error) => {
        if (error.code === 'ERR_CANCELED') return
      })
    }, 500)
    debouncedUpdate()
    return () => {
      debouncedUpdate.clear()
    }
  }, [notes])

  useEffect(() => {
    setSavingIsActive(transferIsSaving)
  }, [transferIsSaving])

  const handleInputOnChange = (event) => {
    setNotes(event.target.value)
    updatePendingTransferNotes(row?.uuid, event.target.value)
  }

  return <EditableInput value={notes} onChange={handleInputOnChange} />
}

export const AdminTransfersContent = () => {
  const [savingIsActive, setSavingIsActive] = useState(false)
  const [isUpdatingData, setIsUpdatingData] = useState(false)
  const [pendingTransfers, setPendingTransfers] = useState([])
  const [processingTransfers, setProcessingTransfers] = useState([])
  const [{ data: pendingTransfersResponse, loading: loadingPendingTransfersResponse }, getPendingTransfers] = useAxios(
    `/transfers/?status__value__in=PENDING,PREAPPROVED,FAILED`,
  )
  const [{ data: processingTransfersResponse, loading: loadingProcessingTransfersResponse }, getProcessingTransfers] =
    useAxios(`/transfers/?status__value__in=PROCESSING,ENROUTE`)

  useEffect(() => {
    setPendingTransfers(pendingTransfersResponse?.results)
  }, [pendingTransfersResponse])

  useEffect(() => {
    setProcessingTransfers(processingTransfersResponse?.results)
  }, [processingTransfersResponse])

  useEffect(() => {
    if (loadingPendingTransfersResponse) {
      setPendingTransfers([])
    }
  }, [loadingPendingTransfersResponse])

  useEffect(() => {
    if (loadingProcessingTransfersResponse) {
      setProcessingTransfers([])
    }
  }, [loadingProcessingTransfersResponse])

  const updatePendingTransferFee = (uuid, newFee) => {
    setPendingTransfers((pendingTransfers) => {
      return pendingTransfers.map((pendingTransfer) => {
        if (pendingTransfer.uuid === uuid) {
          return { ...pendingTransfer, fee: newFee }
        }
        return pendingTransfer
      })
    })
  }

  const updatePendingTransferNotes = (uuid, newNotes) => {
    setPendingTransfers((pendingTransfers) => {
      return pendingTransfers.map((pendingTransfer) => {
        if (pendingTransfer.uuid === uuid) {
          return { ...pendingTransfer, notes: newNotes }
        }
        return pendingTransfer
      })
    })
  }

  const pendingColumns = [
    {
      id: 'number',
      name: 'Number',
      selector: (row) => row.number,
    },
    {
      id: 'requested',
      name: 'Requested',
      selector: (row) => moment(row.created_at).format('MMM DD h:mm A'),
      sortable: true,
      sortFunction: (rowA, rowB) => datetimeSort(rowA, rowB, 'created_at'),
    },
    {
      id: 'email',
      name: 'Email',
      selector: (row) => (
        <StyledLink href={`/accounts?user=${row.user}`} target="_blank" rel="noreferrer">
          {row.user}
        </StyledLink>
      ),
    },
    {
      id: 'name',
      name: 'Name',
      selector: (row) => `${row.first_name} ${row.last_name}`,
    },
    {
      id: 'amount_sending',
      name: 'Amount Sending',
      selector: (row) => `${row.currency_sending?.symbol}${row.amount_sending}`,
    },
    {
      id: 'percent_fee',
      name: 'Percent Fee',
      cell: (row) => (
        <PercentFeeCell
          key={row.uuid}
          updatePendingTransferFee={updatePendingTransferFee}
          setSavingIsActive={setSavingIsActive}
          row={row}
        />
      ),
    },
    {
      id: 'reason',
      name: 'Reason',
      selector: (row) => row.reason,
    },
    {
      id: 'notes',
      name: 'Notes',
      selector: (row) => (
        <NotesCell
          key={row.uuid}
          setSavingIsActive={setSavingIsActive}
          updatePendingTransferNotes={updatePendingTransferNotes}
          row={row}
          isUpdatingData={isUpdatingData}
        />
      ),
    },
    {
      id: 'status',
      name: 'Status',
      selector: (row) => {
        const props = { row, getPendingTransfers, getProcessingTransfers, isUpdatingData, setIsUpdatingData }
        const button =
          row?.status === 'PREAPPROVED' ? ( 
            <PreapprovedButton key={`${row.uuid}-${row.status}`} {...props} />
          ) : row?.status === 'PENDING' ? (
            <PendingButton key={`${row.uuid}-${row.status}`} {...props} />
          ) : row?.status === 'FAILED' ? (
            <FailedButton key={`${row.uuid}-${row.status}`} {...props} />
          ) : (
            <div></div>
          )
        return button
      },
    },
    {
      id: 'cancel',
      name: 'Cancel',
      selector: (row) => (
        <CancelButton
          key={row.uuid}
          row={row}
          getPendingTransfers={getPendingTransfers}
          getProcessingTransfers={null}
          isUpdatingData={isUpdatingData}
          setIsUpdatingData={setIsUpdatingData}
        />
      ),
    },
  ]

  const processingColumns: any = [
    {
      id: 'number',
      name: 'Number',
      selector: (row) => row.number,
    },
    {
      id: 'bitach_id',
      name: 'Bitach ID',
      selector: (row) => <StyledLink href={row.bitach_link}>{row.bitach_transfer}</StyledLink>,
    },
    {
      id: 'requested',
      name: 'Requested',
      selector: (row) => moment(row.created_at).format('MMM DD h:mm A'),
      sortable: true,
      sortFunction: (rowA, rowB) => datetimeSort(rowA, rowB, 'created_at'),
    },
    {
      id: 'submitted',
      name: 'Submitted',
      selector: (row) => moment(row.submitted_at).format('MMM DD h:mm A'),
      sortable: true,
      sortFunction: (rowA, rowB) => datetimeSort(rowA, rowB, 'submitted_at'),
    },
    {
      id: 'email',
      name: 'Email',
      selector: (row) => (
        <StyledLink href={`/accounts?user=${row.user}`} target="_blank" rel="noreferrer">
          {row.user}
        </StyledLink>
      ),
    },
    {
      id: 'name',
      name: 'Name',
      selector: (row) => `${row.first_name} ${row.last_name}`,
    },
    {
      id: 'amount_sending',
      name: 'Amount Sending',
      selector: (row) => `${row.currency_sending?.symbol}${row.amount_sending}`,
    },
    {
      id: 'amount_receiving',
      name: 'Amount Receiving',
      selector: (row) => row?.amount_receiving && `${row.currency_receiving?.symbol}${row.amount_receiving}`,
    },
    {
      id: 'percent_fee',
      name: 'Percent Fee',
      cell: (row) => row.fee?.percent,
    },
    {
      id: 'notes',
      name: 'Notes',
      selector: (row) => row.notes,
    },
    {
      id: 'last_updated',
      name: 'Last Updated',
      selector: (row) => moment(row.updated_at).format('MMM DD h:mm A'),
      sortable: true,
      sortFunction: (rowA, rowB) => datetimeSort(rowA, rowB, 'updated_at'),
    },
    {
      id: 'delivering_at',
      name: 'Delivery Date',
      selector: (row) => (row?.delivering_at ? moment(row.delivering_at.slice(0, 10)).format('MMM DD, YYYY') : 'TBD'),
      sortable: true,
    },
    {
      id: 'status',
      name: 'Status',
      selector: (row) => {
        const props = { row, getPendingTransfers, getProcessingTransfers, isUpdatingData, setIsUpdatingData }
        const button =
          row?.status === 'PROCESSING' ? (
            <ProcessingButton key={`${row.uuid}-${row.status}`} {...props} />
          ) : row?.status === 'ENROUTE' ? (
            <EnrouteButton key={`${row.uuid}-${row.status}`} {...props} />
          ) : (
            <div></div>
          )
        return button
      },
    },
    {
      id: 'cancel',
      name: 'Cancel',
      selector: (row) => (
        <CancelButton
          key={row.uuid}
          row={row}
          getPendingTransfers={null}
          getProcessingTransfers={getProcessingTransfers}
          isUpdatingData={isUpdatingData}
          setIsUpdatingData={setIsUpdatingData}
        />
      ),
    },
  ]

  const usdPendingSum = pendingTransfers
    ?.filter((transfer) => transfer.type.value === 'USD_TO_ILS')
    ?.map((transfer) => transfer.amount_sending)
    ?.reduce((partialSum, amount_sending) => partialSum + Number(amount_sending), 0)

  const ilsPendingSum = pendingTransfers
    ?.filter((transfer) => transfer.type.value === 'ILS_TO_USD')
    ?.map((transfer) => transfer.amount_sending)
    ?.reduce((partialSum, amount_sending) => partialSum + Number(amount_sending), 0)

  const usdProcessingSum = processingTransfers
    ?.filter((transfer) => transfer.type.value === 'USD_TO_ILS')
    ?.map((transfer) => transfer.amount_sending)
    ?.reduce((partialSum, amount_sending) => partialSum + Number(amount_sending), 0)

  const ilsProcessingSum = processingTransfers
    ?.filter((transfer) => transfer.type.value === 'ILS_TO_USD')
    ?.map((transfer) => transfer.amount_sending)
    ?.reduce((partialSum, amount_sending) => partialSum + Number(amount_sending), 0)

  return (
    <>
      {isUpdatingData && <Loading opacity={0.8}></Loading>}
      <TransfersContainer>
        <StyledFieldset>
          <StyledLegend>
            Preapproved & Pending Transfers [${usdPendingSum}] [₪{ilsPendingSum}]
          </StyledLegend>
          <DataTableContainer>
            <StyledDataTable
              columns={pendingColumns}
              data={pendingTransfers}
              progressPending={loadingPendingTransfersResponse}
              pagination
              paginationPerPage={10}
              progressComponent={<LoadingInPlace />}
              fixedHeader
            />
          </DataTableContainer>
        </StyledFieldset>
        <StyledFieldset>
          <StyledLegend>
            Processing & Enroute Transfers [${usdProcessingSum}] [₪{ilsProcessingSum}]
          </StyledLegend>
          <DataTableContainer>
            <StyledDataTable
              columns={processingColumns}
              data={processingTransfers}
              progressPending={loadingProcessingTransfersResponse}
              pagination
              paginationPerPage={10}
              progressComponent={<LoadingInPlace />}
              defaultSortFieldId={'submitted'}
              defaultSortAsc={false}
              fixedHeader
            />
          </DataTableContainer>
        </StyledFieldset>
      </TransfersContainer>
      <SavingContainer active={savingIsActive}>saving transfer...</SavingContainer>
    </>
  )
}
