const CONSTANT_TRANSACTION = {
  canceled: 'Your transfer was canceled and will not be processed.',
  failed: 'Your transfer is being reviewed and will be submitted for processing shortly',
  pending:
    'Your transfer is being reviewed and will be submitted for processing shortly',
  preapproved: 'Your transfer may require additional supporting documentation. If necessary, a team member will reach out.',
  processing:
    'Your transfer has been placed into queue for debit. No further action is needed.',
  completed:
    'Funds have left the OlehPay system and have been sent to your account. Depending on your financial institution, it may take a day or two for your account balance to reflect the deposit from OlehPay.',
};

export default CONSTANT_TRANSACTION;
